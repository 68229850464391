import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import CompanyGrid from '../components/company-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

export const query = graphql`
  query CompaniesPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)company/" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
    }

    companies: allSanityCompany(filter: {display: {ne: false}}, sort: {fields: [sortOrder, investmentDate, title], order: [DESC, DESC, ASC]}) {
      edges {
        node {
          title
          _id
          _key
          sortOrder
          investmentDate
          slug {
            current
          }
          _rawExcerpt
          link
          logo {
            asset {
              _id
              url
            }
          }
          brandColor {
            hex
          }
          mainImage {
            asset {
              _id
              url
            }
          }
          sectors {
            _id
            title
          }
          stage {
            _id
            title
          }
        }
      }
    }

    sectors: allSanitySector(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }

    stages: allSanityStage(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`

const CompaniesPage = props => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const page = data.page
  // console.log(page);
  const sectorNodes = (data || {}).sectors
    ? mapEdgesToNodes(data.sectors)
    : []
  const stageNodes = (data || {}).stages
    ? mapEdgesToNodes(data.stages)
    : []
  const rawCompanyNodes =
    data && data.companies && mapEdgesToNodes(data.companies).filter(filterOutDocsWithoutSlugs)

  const sortedCompanies = 
    rawCompanyNodes && rawCompanyNodes.filter(company => company.sortOrder !== null)

  const currentDate = new Date(new Date().setFullYear(new Date().getFullYear() -   1));
  const formattedDate = `${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, '0')}${currentDate.getDate()}`;

  const newestCompanies = 
    rawCompanyNodes && rawCompanyNodes.filter(company => company.sortOrder === null && Number(company.investmentDate.replaceAll('-', '')) >= Number(formattedDate) );

  const unsortedCompanies = 
    rawCompanyNodes && rawCompanyNodes.filter(company => company.sortOrder === null && Number(company.investmentDate.replaceAll('-', '')) < Number(formattedDate) ).sort((a,b) => {
      a = a.title.toLowerCase();
      b = b.title.toLowerCase();
      if ( a == b) return 0;
      return a < b ? -1 : 1;
  });
  
  const companyNodes = [...sortedCompanies, ...newestCompanies, ...unsortedCompanies];
  return (
    <Layout>
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} />

      <Hero>
        {page.title}
      </Hero>

      {companyNodes && companyNodes.length > 0 && <CompanyGrid nodes={companyNodes} sectors={sectorNodes} stages={stageNodes} body={page._rawBody} />} 
    </Layout>
  )
}

export default CompaniesPage
