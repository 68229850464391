import React from 'react'
import Icon from './icons'
import { gsap } from "gsap";

import styles from './search.module.css'

function search () {
    const searchTeam = event => {
        const query = event.target.value;
        const displayContainer = document.querySelector(`[data-display-items]`);
        gsap.fromTo('[data-display-items]', { opacity: 1 }, { duration: 0.3, opacity: 0, onComplete: () => {
            displayContainer.style.display = 'grid';
            const children = displayContainer.childNodes;
            let resultFound = false;
            displayContainer.classList.remove(styles.noneFound);
            for (let c = 0; c < children.length; c++) {
                const name = children[c].dataset['filterName'];
                if (name.toLowerCase().includes(query.toLowerCase())) {
                    children[c].style.display = 'block';
                    resultFound = true;
                } else {
                    children[c].style.display = 'none';
                }
            }

            gsap.fromTo('[data-display-items]', { opacity: 0 }, { duration: 0.3, opacity: 1 });

            if (!resultFound) {
                displayContainer.classList.add(styles.noneFound);
            }
        }});
    }

    return (
        <div className={styles.search}>
            <input type="text" placeholder="Find company..." onChange={searchTeam} />
            <Icon symbol='search' />
        </div>
    )
}

export default search